@font-face {
  font-family: 'Bw';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./assets/fonts/BwGradual-Regular.woff2') format('woff2'),
    url('./assets/fonts/BwGradual-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Bw thin';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./assets/fonts/BwGradual-Thin.woff2') format('woff2'),
    url('./assets/fonts/BwGradual-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Bw bold';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./assets/fonts/BwGradual-Bold.woff2') format('woff2'),
    url('./assets/fonts/BwGradual-Bold.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Bw', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #2a3139;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bw bold';
  font-weight: normal;
}

button {
  cursor: pointer;
  outline: 0;
}

input,
textarea {
  outline: 0;
}

a {
  color: inherit;
}

:root {
  --slideshow-height: 80vh;
  --slideshow-background: rgba(0, 0, 0, 0.3);
}
